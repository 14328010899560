module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.25,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1E8YLRQ367"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":3600},"pluginConfig":{"head":false,"exclude":["/pages/dish.tsx","/pages/categoryList.tsx"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smaki Azji Siemianowice Śląskie","short_name":"SmakiAzji","start_url":"/","lang":"pl","background_color":"#1e0f08","theme_color":"#f09b11","display":"standalone","icon":"src/assets/icons/favicon.png","icons":[{"src":"src/assets/icons/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"src/assets/icons/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"src/assets/icons/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"src/assets/icons/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"src/assets/icons/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0","purpose":"any maskable"},{"src":"src/assets/icons/favicon-512x512.png","sizes":"512x512","type":"image/png","density":"4.0"},{"src":"src/assets/icons/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0","purpose":"maskable"}],"legacy":false,"cache_busting_mode":"name","crossOrigin":"use-credentials","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"f00a54c6b8785888b6580bc459afec59"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
